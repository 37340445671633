html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Pretendard', 'Noto Sans KR', 'Malgun Gothic', 'Helvetica',
    'Myriad Pro', 'Arial';
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

button {
  font-family: 'Pretendard', 'Noto Sans KR', 'Malgun Gothic', 'Helvetica',
    'Myriad Pro', 'Arial';
}

/* remove blue highlight on mobile */
* {
  -webkit-tap-highlight-color: transparent;
}
